import { graphql } from "gatsby";
import React from "react";
import Layout from "../../components/Layout";
import Photos from "../../components/Photos";

function PhotosPage({ location, data }) {
  return (
    <Layout location={location} languages={data.site.siteMetadata.languages}>
      <Photos photos={data.photos.photos} />
    </Layout>
  );
}

export default PhotosPage;

export const pageQuery = graphql`
  query PhotosEnQuery {
    site {
      siteMetadata {
        languages {
          defaultLangKey
          langs
        }
      }
    }
    photos: datoCmsPhoto {
      photos {
        gatsbyImageData(layout: CONSTRAINED, width: 400, placeholder: BLURRED)
        url
        tags
      }
    }
  }
`;
